<!-- <div class="menu-title">{{menu.name}}</div> -->
<a
    routerLink="/"
    class="logo-container img-logo-container"
    *ngIf="config.get('branding.logo_light')"
  >
    <img class="logo" [alt]="'Site logo' | trans" [src]="config.get('branding.logo_light')" />
  </a>
  <a routerLink="/" class="logo-container" *ngIf="!config.get('branding.logo_light')">
    <div class="text-logo">{{ config.get('branding.site_name') }}</div>
  </a>
<ng-container *ngFor="let item of menu.items">
    <ng-container *ngIf="shouldShow(item?.condition)">
        <ng-container [ngSwitch]="getItemType(item)">
            <a
              *ngSwitchCase="'link'"
              class="nav-item custom-menu-item link-nav-item {{ itemClass }}"
              [href]="item.action"
              [ngClass]="toSnakeCase(item.label)"
              [target]="item.target"
            >
                <mat-icon [svgIcon]="item.icon" *ngIf="item.icon"></mat-icon>
                <span class="text" trans>{{ item.label }}</span>
            </a>

            <ng-container *ngIf="parseRoute(item.action) as route">
                <a
                  *ngSwitchCase="'route'"
                  class="nav-item custom-menu-item route-nav-item {{ itemClass }}"
                  [routerLink]="'/' + route.link"
                  [queryParams]="route.queryParams"
                  [ngClass]="toSnakeCase(item.label)"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{exact: item.action === '/' || item.activeExact}"
                  [target]="item.target"
                >
                    <mat-icon [svgIcon]="item.icon" *ngIf="item.icon"></mat-icon>
                    <span class="text" trans>{{ item.label }}</span>
                </a>
            </ng-container>
        </ng-container>

    </ng-container>
</ng-container>

<ng-content></ng-content>
