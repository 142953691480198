<div class="navbar-container" [class.container]="container">
  

  <button
    class="toggle-sidebar-button"
    mat-icon-button
    *ngIf="shouldShowToggleBtn()"
    (click)="toggleButtonClick.emit()"
    aria-label="Toggle sidebar visibility"
  >
    <mat-icon svgIcon="menu"></mat-icon>
  </button>

  <ng-content></ng-content>

  <ng-container *ngIf="(mobileOrTablet$ | async) && menuPosition && !shouldShowToggleBtn()">
    <button class="toggle-sidebar-button" mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon svgIcon="menu"></mat-icon>
    </button>

    <mat-menu #menu="matMenu" [overlapTrigger]="false">
      <custom-menu [position]="menuPosition" itemClass="mat-menu-item"></custom-menu>
    </mat-menu>
  </ng-container>

  <custom-menu
    [position]="menuPosition"
    [horizontal]="true"
    [class.color-accent]="color === 'accent'"
    *ngIf="!(mobileOrTablet$ | async)"
  ></custom-menu>

  <div class="right-side-actions" *ngIf="!hideRightSideActions">
    <ng-content select="[rightContent]"></ng-content>
    <notification-button
      *ngIf="
        !isMobile() && config.get('notifications.integrated') && currentUser.isLoggedIn$ | async
      "
    ></notification-button>
    <ng-container *ngIf="searchInput && (mobileOrTablet$ | async)">
      <button
        mat-icon-button
        class="mobile-search-toggle"
        (click)="openMobileSearch()"
        *ngIf="!(mobileSearchActive$ | async); else closeToggle"
      >
        <mat-icon svgIcon="search"></mat-icon>
      </button>
      <ng-template #closeToggle>
        <button mat-icon-button class="mobile-search-toggle" (click)="closeMobileSearch()">
          <mat-icon svgIcon="close"></mat-icon>
        </button>
      </ng-template>
    </ng-container>
    <logged-in-user-widget [hideRegisterButton]="hideRegisterButton"></logged-in-user-widget>
  </div>
</div>
